<template>
  <div class="container">
    <div class="f1 titles wow fadeInUp">2011-2022认证历程</div>
    <div class="content-img wow fadeInUp">
      <img class="pc" :src="require('@/assets/home/attestation.png')" />
      <img class="moblie" :src="require('@/assets/home/m-attestation.png')" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    width: 100%;
    background: #ffffff;
    //padding-bottom: 0.97rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 0.6rem;
  }
  .content-img {
    max-width: 1347px;
    margin-top: 0.3rem;
    img {
      width: 100%;
    }
  }
  .titles {
    padding-top: 0.9rem;
    @if ($searchWidth<1100) {
      padding-top: 2.5rem;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
