import { createRouter, createWebHistory } from "vue-router";
//createWebHashHistory
import Home from "../views/home";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "RFID服装管理系统_RFID吊牌_服装RFID软件-深圳常达智能物联",
      keywords:
        "rfid服装,服装软件,rfid技术应用,RFID系统,rfid标签天线,服装生产erp管理软件,射频识别标签,RFID标签制造商,服装RFID,RFID技术,智慧门店,自动分拣,RFID系统方案集成",
      description:
        "RFID技术已经被被服装行业所用，像全棉时代、海澜之家、优衣库、迪卡侬等大牌已经将RFID技术融入到仓储物流、零售和全渠道销售等各个环节中。我们提供一站式RFID鞋服行业解决方案，包括RFID系统、标签、硬件。常达智能物联为全资港资企业，集团于1991年开始制造印刷吊牌和价格标签。我们在中国、孟加拉国、越南、土耳其、洛杉矶、危地马拉、印度、墨西哥设立了生产基地，并且在全球40多个国家成立了办事处。",
    },
  },
  {
    path: "/tags",
    name: "Tags",
    component: () => import("@/views/tags/index"),
    meta: {
      title: "RFID产品中心_RFID吊牌_服装RFID硬件产品-深圳常达智能物联",
      keywords:
        "RFID电子标签,RFID读写器,RFID手持机,RFID打印机,RFID智能柜,RFID硬件产品,rfid电子标签厂家,RFID标签,RFID天线,RFID自助收银机,RFID布草分拣,斑马打印机",
      description:
        "欢迎来到我们的RFID产品中心页面，我们提供高品质的RFID电子标签、RFID读写器、RFID手持机、RFID打印机、RFID智能柜以及其他RFID硬件产品。我们的RFID产品中心采用最先进的技术，具有高度的可靠性和性能，可广泛应用于零售、物流和供应链管理等领域。无论您需要什么样的RFID硬件产品，我们都可以为您提供优质的解决方案。联系我们，让我们共同解决您的RFID硬件需求。",
    },
  },
  {
    path: "/software",
    name: "software",
    component: () => import("@/views/software/index"),
    meta: {
      title:
        "自动接单_AI自动排版_RFID鞋服工厂数据中心_RFID鞋服仓储系统_RFID鞋服门店系统-深圳常达智能物联",
      keywords:
        "RFID软件,RFID系统,rfid扫描软件,rfid读写器软件,RFID服装盘点,RFID软件集成,RFID鞋服工厂数据中心,RFID鞋服仓储系统,RFID鞋服门店系统,RFID库存盘点系统,常达",
      description:
        "发现适用于鞋类品牌商、制造商和零售商的终极软件平台！我们的软件平台包括自动接单系统和AI自动排版系统，以简化您的工作流程。通过我们的RFID鞋服工厂数据中心系统，您可以轻松管理生产过程并实时跟踪库存。我们的RFID鞋服仓储系统确保您的产品安全高效地存储。通过我们的RFID鞋服门店系统，您可以轻松管理零售业务。立即尝试我们的平台，将您的业务提升到新的高度！",
    },
  },
  {
    path: "/plans",
    name: "plans",
    component: () => import("@/views/plans/index"),
    meta: [
      {
        name: "贴标管理",
        meta: {
          title:
            "贴标管理_标签固定_资产挂标_鞋服行业RFID系统方案集成-深圳常达智能物联",
          keywords:
            "RFID标签应用,鞋服物流管理,垂直整合服务,应用程序集成,RFID标签制造,关键信息管理,RFID服装管理系统,服装RFID解决方案,服装RFID系统,服装rfid吊牌,RFID标签,RFID吊牌",
          description:
            "深圳常达智能物联为鞋服行业提供贴标管理系统，实现标签固定及资产挂标。我们提供集成RFID系统，包括RFID标签、RFID吊牌，帮助企业实现贴标管理和关键信息管理。我们提供RFID服装管理系统、服装RFID解决方案等应用程序集成服务，实现垂直整合及鞋服物流管理。同时，我们还提供RFID标签制造服务，帮助企业提高管理效率，提升竞争力。",
        },
      },
      {
        name: "衣服盘点",
        meta: {
          title:
            "衣服盘点_零售店铺_物流仓储_鞋服行业RFID系统方案集成-深圳常达智能物联",
          keywords:
            "RFID技术,零售物流管理,仓储管理系统智能,RFID服装管理系统,服装RFID解决方案,服装RFID系统,库存盘点,服装rfid吊牌,RFID标签,实时监控,生产流程管理,RFID吊牌,供应链管理",
          description:
            "深圳常达智能物联提供可靠的RFID技术解决方案，帮助鞋服行业、零售店铺、物流仓储等企业实现智能管理。我们提供RFID服装管理系统、仓储管理系统智能、库存盘点方案等集成RFID系统，同时提供RFID标签、RFID吊牌，实现实时监控及供应链管理，提高生产流程管理效率。",
        },
      },
      {
        name: "智能货架",
        meta: {
          title:
            "智能货架_家乐福_全棉时代_鞋服行业RFID系统方案集成-深圳常达智能物联",
          keywords:
            "电子标签系统,智慧物流解决方案,RFID货架系统,零售物联网,供应链管理系统,RFID技术,RFID服装管理系统,服装RFID解决方案,服装RFID系统,服装rfid吊牌,RFID标签,RFID吊牌",
          description:
            "深圳常达智能物联为鞋服行业提供集成RFID系统，实现智能货架及物流管理。我们提供电子标签系统，RFID货架系统，帮助企业实现智慧物流解决方案及零售物联网。我们提供RFID技术，供应链管理系统，RFID服装管理系统，服装RFID解决方案，服装RFID系统等应用程序集成服务，实现智能货架管理，提高企业管理效率及竞争力。我们还提供服装rfid吊牌、RFID标签等产品，帮助企业更好地实现关键信息管理。",
        },
      },
      {
        name: "智能试衣间",
        meta: {
          title:
            "智能试衣间_智能试衣_数据采集_鞋服行业RFID系统方案集成-深圳常达智能物联",
          keywords:
            "RFID试衣间系统,客户行为分析,数据挖掘技术,试衣间系统整合,在线销售分析,实时库存查询,RFID服装管理系统,服装RFID解决方案,服装RFID系统,服装rfid吊牌,RFID标签,RFID吊牌",
          description:
            "深圳常达智能物联为鞋服行业提供集成RFID系统，实现智能试衣间及试衣数据采集。我们提供RFID试衣间系统，客户行为分析，数据挖掘技术等应用程序集成服务，帮助企业实现在线销售分析、实时库存查询，提高了企业试衣间的使用效率，提升管理效率及竞争力。我们还提供服装rfid吊牌、RFID标签等产品，帮助企业更好地实现关键信息管理。",
        },
      },
      {
        name: "快速查找",
        meta: {
          title:
            "快速查找_快速找货_物流仓储_鞋服行业RFID系统方案集成-深圳常达智能物联",
          keywords:
            "物流信息管理, RFID找货系统,快速库存查询,货物移动追踪,仓库管理系统,供应链管理系统,RFID服装管理系统,服装RFID解决方案,服装RFID系统,服装rfid吊牌,RFID标签,RFID吊牌",
          description:
            "深圳常达智能物联为鞋服行业提供集成RFID系统，实现物流仓储快速查找及货物移动追踪。我们提供RFID找货系统，快速库存查询，供应链管理系统等应用程序集成服务，帮助企业实现货物的快速查找及移动追踪，提高了企业物流仓储的效率，提升管理效率及竞争力。我们还提供服装rfid吊牌、RFID标签等产品，帮助企业更好地实现关键信息管理。",
        },
      },
      {
        name: "快速收银",
        meta: {
          title:
            "快速收银_自助收银_物流仓储_鞋服行业RFID系统方案集成-深圳常达智能物联",
          keywords:
            "自助购物解决方案,快速结账通道,RFID库存管理,自动化收银方案,货物移动追踪,货物批次管理,RFID服装管理系统,服装RFID解决方案,服装RFID系统,服装rfid吊牌,RFID标签,RFID吊牌",
          description:
            "深圳常达智能物联为鞋服行业提供集成RFID系统，实现自助收银及货物移动追踪。我们提供自助购物解决方案，快速结账通道，RFID库存管理等应用程序集成服务，帮助企业实现自动化收银、货物移动追踪等功能，提高了企业收银效率，提升管理效率及竞争力。我们还提供服装rfid吊牌、RFID标签等产品，帮助企业更好地实现关键信息管理。",
        },
      },
      {
        name: "防盗防损",
        meta: {
          title:
            "防盗防损_防盗门_质量追踪_鞋服行业RFID系统方案集成-深圳常达智能物联",
          keywords:
            "RFID防盗门,防伪验证技术,商品追踪系统,安全门禁管理系统,安全监控系统,自动化库存系统,RFID服装管理系统,服装RFID解决方案,服装RFID系统,服装rfid吊牌,RFID标签,RFID吊牌",
          description:
            "深圳常达智能物联为鞋服行业提供集成RFID系统，实现防盗防损及货物质量追踪。我们提供RFID防盗门，商品追踪系统，安全监控系统等应用程序集成服务，帮助企业实现防盗防损、货物质量追踪等功能，保障了企业货物流通安全，提升管理效率及竞争力。我们还提供服装rfid吊牌、RFID标签等产品，帮助企业更好地实现关键信息管理。",
        },
      },
    ],
  },
  {
    path: "/tidings",
    name: "News",
    component: () => import("@/views/news/index"),
    meta: {
      title: "新闻中心_RFID鞋服解决方案分享_RFID鞋服行业动态-深圳常达智能物联",
      keywords:
        "RFID行业新闻,RFID技术趋势,RFID应用解决方案,RFID标签制造,RFID零售解决方案,文章分享,RFID技术案例,客户案例研究,RFID行业分析报道,RFID服装管理系统,服装RFID系统",
      description:
        "深圳常达智能物联为鞋服行业提供RFID行业相关的新闻资讯、技术趋势分析、应用解决方案的分享。我们制造RFID标签，提供RFID零售解决方案等。通过文章分享、客户案例研究、RFID行业分析报道等形式，我们让客户更好地了解RFID技术及应用，提高企业的行业竞争力，帮助企业更好地实现服装RFID管理系统。",
    },
  },
  {
    path: "/child",
    name: "child",
    component: () => import("@/views/newsChild/index"),
    meta: {
      title: "新闻中心_RFID鞋服解决方案分享_RFID鞋服行业动态-深圳常达智能物联",
      keywords:
        "RFID行业新闻,RFID技术趋势,RFID应用解决方案,RFID标签制造,RFID零售解决方案,文章分享,RFID技术案例,客户案例研究,RFID行业分析报道,RFID服装管理系统,服装RFID系统",
      description:
        "深圳常达智能物联为鞋服行业提供RFID行业相关的新闻资讯、技术趋势分析、应用解决方案的分享。我们制造RFID标签，提供RFID零售解决方案等。通过文章分享、客户案例研究、RFID行业分析报道等形式，我们让客户更好地了解RFID技术及应用，提高企业的行业竞争力，帮助企业更好地实现服装RFID管理系统。",
    },
  },
  {
    path: "/About",
    name: "About",
    component: () => import("@/views/about/index"),
    meta: {
      title: "关于我们_RFID鞋服行业系统解决方案供应商_深圳常达智能",
      keywords:
        "RFID解决方案供应商,RFID系统集成商,RFID系统服务商,RFID解决方案定制,RFID行业咨询服务,专业的RFID系统供应商,RFID行业分析报道,RFID服装管理系统,服装RFID系统",
      description:
        "常达智能物联为全资港资企业，集团于1991年开始制造印刷吊牌和价格标签。常达专注于RFID核心技术、拥有自主研发团队，开发鞋服行业RFID系统平台/RFID产品与鞋服行业RFID项目解决方案，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。我们在中国、孟加拉国、越南、土耳其、洛杉矶、危地马拉、印度、墨西哥设立了生产基地，并且在全球40多个国家成立了办事处。",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
function setMeta(name, value) {
  let dom = document.querySelector(`meta[name="${name}"]`);
  dom && dom.setAttribute("content", value);
}
router.beforeEach((to) => {
  let meta = {};
  if (to.name == "plans") {
    for (let index in to.meta) {
      if (to.meta[index].name == to.query.name) {
        meta = to.meta[index].meta;
      }
    }
  } else {
    meta = to.meta;
  }
  let { title, keywords, description } = meta;
  if (keywords) {
    setMeta("keywords", keywords);
  }
  if (description) {
    setMeta("description", description);
  }
  if (title) {
    setMeta("title", title);
  }
});

// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`;
//
//     if (route.path !== "*") {
//       array.push(path);
//     }
//
//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`));
//     }
//
//     return array;
//   }, []);
// }

// getRoutesList(router.options.routes, 'https://zigamiklic.com');
// function getRoutesXML() {
//   const list = getRoutesList(
//     router.options.routes,
//     "https://www.cirteklink.com.cn"
//   )
//     .map((route) => `<url><loc>${route}</loc></url>`)
//     .join("\r\n");
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }

// console.log(getRoutesXML())
export default router;
