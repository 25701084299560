<template>
  <div>
    <div class="main pc">
      <div class="f1 wow fadeInUp">全球业务 + 制造</div>
      <span class="subtitle wow fadeInUp">无论身在何处，常达均能为你提供优质的服务</span>
      <div class="nums-warp">
        <div class="num-left">
          <template v-for="(item, index) in left" :key="index">
            <aboutNumComponent
              :nums="item.nums"
              :unit="item.unit"
              :mes="item.mes"
            ></aboutNumComponent>
          </template>
        </div>
        <div class="num-right">
          <template v-for="(item, index) in right" :key="index">
            <aboutNumComponent
              :nums="item.nums"
              :unit="item.unit"
              :mes="item.mes"
            ></aboutNumComponent>
          </template>
        </div>
      </div>
    </div>
    <div class="main moblie">
      <div>
        <div class="f1 wow fadeInUp">全球业务 + 制造</div>
        <span class="subtitle wow fadeInUp">无论身在何处，常达均能为你提供优质的服务</span>
      </div>
      <div class="nums-warp-m">
        <div class="num-left-m">
          <template v-for="(item, index) in left" :key="index">
            <aboutNumComponent
              :nums="item.nums"
              :unit="item.unit"
              :mes="item.mes"
            ></aboutNumComponent>
          </template>
        </div>
        <div class="num-right-m">
          <template v-for="(item, index) in right" :key="index">
            <aboutNumComponent
              :nums="item.nums"
              :unit="item.unit"
              :mes="item.mes"
            ></aboutNumComponent>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import aboutNumComponent from "@components/about/about-num-component.vue";
export default {
  components: { aboutNumComponent },
  data() {
    return {
      left: [
        {
          nums: 8,
          unit: "个",
          mes: "国家设立工厂",
        },
        {
          nums: 40,
          unit: "+",
          mes: "成立办事处",
        },
        {
          nums: 400,
          unit: "部",
          mes: "生产机器",
        },
      ],
      right: [
        {
          nums: 16,
          unit: "项",
          mes: "实用型专利",
        },
        {
          nums: 13,
          unit: "项",
          mes: "软件著作权",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .main {
    display: flex;
    width: 100%;
    min-height: 6.5rem;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-image: url("~@/assets/home/about/1.png");
    &.moblie{
      background-image: url("~@/assets/home/about/3.png");
      min-height: 600px;
      justify-content: space-between;
      .subtitle {
        font-size: 0.6rem;
      }
    }
    @if ($searchWidth<1100) {
      height: auto;
      background-size: cover;
      background-position: center;
    } @else {
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .subtitle {
      margin-top: 0.2rem;
      font-size: 0.16rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;
      line-height: 22px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @if ($searchWidth<1100) {
        // width: 40%;
        margin: 1rem auto 1.2rem;
      } @else {
        max-width: 3.9rem;
        margin: 0.64rem auto 1.2rem;
      }
    }
    .nums-warp {
      height: 70%;
      padding: 0 4rem;
      width: calc(100% - 8rem);
      display: flex;
      justify-content: space-between;
      .num-right {
        align-self: flex-end;
        transform: translateY(1rem);
      }
    }
    .nums-warp-m{
      margin-left: 18px;
      margin-bottom: 66px;
      align-self: flex-start;
      .num-left-m{
        display: flex;
        margin-bottom: 30px;
      }
      .num-right-m{
        display: flex;
      }
    }

    .t {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #5ec7b9;
      letter-spacing: 0.01rem;

      @if ($searchWidth<1100) {
        font-size: 1rem;
        text-align: center;
        margin: auto;
      } @else {
        font-size: 0.38rem;
      }
    }

    .info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;
      margin: 0.5rem 0 1rem;
      p {
        white-space: nowrap;
      }
      //word-break: normal;
      @if ($searchWidth<1100) {
        line-height: 0.9rem;
        font-size: 0.7rem;
        text-align: center;
      } @else {
        line-height: 0.35rem;
        font-size: 0.18rem;
      }
    }

    .btn {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      background: #5ec7b9;
      cursor: pointer;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-radius: 0.75rem;
        padding: 0 1.65rem;
        margin: auto;
      } @else {
        font-size: 0.24rem;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.25rem;
        padding: 0 0.52rem;
      }
    }

    .image {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      @if ($searchWidth<1100) {
        width: 100%;
      } @else {
        width: 6.9rem;
      }

      img {
        width: 100%;
        display: block;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
