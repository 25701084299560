<template>
  <div class="home-top">
    <Swiper
      :slides-per-view="1"
      :effect="'fade'"
      :fadeEffect="{ crossFade: true }"
      @slideChangeTransitionEnd="slideChangeTransitionEnd"
      :loop="true"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :speed="800"
      transitionDuration="'600ms'"
      :pagination="{ clickable: true }"
      :modules="modules"
    >
      <SwiperSlide v-for="(item, index) in bgImg" :key="index">
        <img class="pc" :src="require('@/assets/home/' + item)" />
        <img class="moblie" :src="require('@/assets/home/' + mBgImg[index])" />
        <div class="text">
          <div class="t1">
            {{ titleList[index]["title"] }}
          </div>
          <div class="t2">
            {{ titleList[index]["subTitle"] }}
          </div>
          <a
            class="btn"
            target="_blank"
            href="http://120.76.44.28/clothing-20240327/"
            >服装系统试用</a
          >
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Swiper as swiper, Mousewheel } from "swiper";
import { Autoplay, Pagination, EffectFade } from "swiper";
import "swiper/swiper-bundle.min.css";
swiper.use(Mousewheel);
export default {
  data() {
    return {
      bgImg: ["bg.gif", "bg1.png", "bg2.png"],
      mBgImg: ["m-bg.gif", "m-bg1.png", "m-bg2.png"],
      modules: [Autoplay, Pagination, EffectFade],
      titleList: [
        {
          title: "RFID技术让服装数据动起来",
          subTitle: "吊牌、织唛、贴标、热转印等一应俱全",
        },
        {
          title: "走向数字化的智能战略",
          subTitle: "自动接单、AI自动排版、RFID鞋服门店系统",
        },
        {
          title: "鞋服企业的RFID解决方案",
          subTitle: "RFID鞋服仓储系统、RFID工厂数据中心",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .home-top {
    position: relative;
    @if ($searchWidth<1100) {
      height: 100vh;
    } @else {
      height: 100%;
    }
    img {
      @if ($searchWidth<1100) {
        height: 100vh;
        object-fit: cover;
        width: 100%;
      } @else {
        height: 100%;
        width: 100%;
        display: block;
      }
    }
    ::v-deep(.swiper-pagination) {
      width: 13.2rem;
      text-align: left;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0.75rem;
      overflow: hidden;
      @if ($searchWidth<1100) {
        text-align: center;
      }
      .swiper-pagination-bullet {
        width: 0.64rem;
        height: 0.04rem;
        border-radius: 0;
        background: #ced2d6;
        opacity: 1;
        margin: 0 10px 0 0;
        overflow: hidden;
        @if ($searchWidth<1100) {
          width: 38px;
          height: 2px;
        }
      }
      .swiper-pagination-bullet-active::before {
        clear: both;
        content: "";
        background: #5ec7b9;
        width: 0.64rem;
        height: 0.04rem;
        position: relative;
        left: -100%;
        top: 0;
        display: block;
        animation: itemIn 5s linear;
        @if ($searchWidth<1100) {
          width: 38px;
          height: 2px;
        }
      }
    }
    ::v-deep(.swiper-slide-active) {
      .t1,
      .t2,
      .btn {
        animation: fadeInRights 7s infinite;
        animation-fill-mode: both;
      }
      .t2 {
        animation-delay: 0.3s;
      }
      .btn {
        animation-delay: 0.4s;
      }
    }
  }
  .text {
    position: absolute;
    top: 40%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 13.2rem;
    z-index: 100;
    @if ($searchWidth<1100) {
      align-items: center;
      top: 33%;
    }

    .t1 {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      @if ($searchWidth<1100) {
        font-size: 1rem;
      } @else {
        font-size: 0.55rem;
      }
    }

    .t2 {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      color: #333333;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        margin: 0.75rem 0 1.25rem;
      } @else {
        font-size: 0.3rem;
        margin: 0.1rem 0 0.4rem;
      }
    }

    .btn {
      background: #5ec7b9;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      display: inline-block;
      text-decoration: none;
      &:hover {
        background: #77e0d2;
      }
      @if ($searchWidth<1100) {
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
        padding: 0 1.2rem;
        border-radius: 8px;
      } @else {
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.2rem;
        padding: 0 0.38rem;
        border-radius: 0.08rem;
      }
    }
  }
  @keyframes itemIn {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(0.64rem);
      @if ($searchWidth<1100) {
        transform: translateX(38px);
      } @else {
        transform: translateX(0.64rem);
      }
    }
  }
}

@keyframes fadeInRights {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  20% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
