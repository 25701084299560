<template>
  <div class="smart-clothing-b" :style="containerStyle">
    <div class="main" ref="main">
      <div class="list" @mouseleave ="current = null">
        <div class="mask">
          <div
            class="mask-item"
            :style="itemWidth && `width:${itemWidth}%`"
            :class="{ current: index === current }"
            v-for="(item, index) in list"
            :key="index"
            @mouseenter.stop="current = index"
          >
            <div v-if="current === index" @click="goPlanDetial(item.text)">
              <div class="item-hover">
                <span>{{item.text}}</span>
              </div>
              <img class="right-img" :src="require('@/assets/toRight.png')">
            </div>
            <div class="item-text" v-html="item.text" v-else></div>
<!--            <div class="item-info" :class="{ current: current === index }">-->
<!--              {{ item.info }}-->
<!--            </div>-->
          </div>
        </div>
        <div
          class="list-box"
          :style="current && `transform: translateX(-${current * itemWidth}%);`"
        >
          <div
            class="item"
            :style="itemWidth && `width:${itemWidth}%`"
            :class="{ current: index === current, all: current !== null }"
            v-for="(item, index) in list"
            :key="index"
          >
            <!--          <div class="item-t">{{ item.text }}</div>-->
            <div
              class="image"
              :style="{
                width: imageWidth ? imageWidth + 'px' : '15.78rem',
              }"
            >
              <img
                :style="`transform: translateX(${item.imgOffset});`"
                :src="item.image"
                :alt="format(item.text)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    containerStyle: Object,
  },
  data() {
    return {
      imageWidth: "",
      current: null,
    };
  },
  computed: {
    itemWidth() {
      return 100 / this.list.length;
    },
  },
  methods: {
    format(str) {
      let reg = /<\/?.+?\/?>/g;
      return str.replace(reg, "");
    },
    goPlanDetial(name){
      this.$router.push({
        name:'plans',
        query: {
          name:name
        }
      })
    },
  },
  mounted() {
    this.imageWidth = this.$refs.main.offsetWidth;
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .smart-clothing-b {
    background: #f7f9fa;
    // border: 1px solid red;
    overflow: hidden;

    width: 100%;

    .main {
      overflow: hidden;

      // width: 100%;
      //padding: 0.6rem 0;
      @if ($searchWidth<1100) {
        margin: 0 0.5rem;
        box-sizing: border-box;
        // max-width: 21.2rem;
      } @else {
        max-width: 13.2rem;
        margin: auto;
      }

      .title {
        font-size: 0.38rem;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        line-height: 0.52rem;
        letter-spacing: 0.01rem;
        text-align: center;
        margin-bottom: 0.4rem;
      }

      .list {
        position: relative;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.4s;
        position: absolute;
        width: 100%;
        height: 100%;
        //background: rgba(0, 0, 0, 0.3);
        display: flex;
        z-index: 11;

        //opacity: 0;
        > .mask-item {
          background: rgba(51, 51, 51, 0.45);
          width: 14.285%;
          font-size: 0.2rem;
          font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #ffffff;
          //padding-top: 1.65rem;
          box-sizing: border-box;
          //text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          > div {
            transition: all 0.5s;
          }

          @if ($searchWidth<1100) {
            line-height: 1.3;
          } @else {
            line-height: 0.27rem;
          }

          &.current {
            background: rgba(51, 51, 51, 0.1);
            color: #5ec7b9;
          }

          .item-hover{
            width: 30px;
            cursor: pointer;
            span{
              font-size: 30px;
              font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
              font-weight: normal;
              color: #FFFFFF;
              line-height: 42px;
              letter-spacing: 1px;
            }
          }
          .right-img{
            margin-top: 30px;
            cursor: pointer;
            width: 30px;
          }

          .item-text {
            font-size: 0.34rem;
            margin-bottom: 0.2rem;
            //padding: 0 0.2rem;
            //width: 100%;
            //box-sizing: border-box;
          }

          .item-icon {
            width: 0.6rem;
            height: 0.6rem;
            //border: 1px solid red;
            margin: 0 auto 0.2rem;
            background-size: 100%;
            background-repeat: no-repeat;
            //img {
            //  width: 100%;
            //}
          }

          .item-info {
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #ffffff;
            letter-spacing: 0.01rem;
            max-height: 0;
            overflow: hidden;
            //border: 1px solid red;
            //transition: all 0.5s;
            box-sizing: border-box;
            text-align: left;

            //width: 100%;

            @if ($searchWidth<1100) {
              line-height: 1.3;
              font-size: 0.32rem;
              padding: 0 0;

              &.current {
                max-height: 7.5rem;
              }
            } @else {
              line-height: 0.25rem;
              font-size: 0.16rem;

              padding: 0 0.18rem;

              &.current {
                max-height: 3.5rem;
              }
            }
          }

          .item-more {
            font-size: 0.16rem;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;

            line-height: 1.2;
            margin-top: 0.05rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0.15rem;

            img {
              width: 0.2rem;
            }
          }
        }
      }

      .list-box {
        display: flex;
        //overflow: hidden;
        transition: all 0.2s;

        .item {
          width: 14.285%;
          flex-shrink: 0;
          transition: all 0.2s;
          display: flex;
          align-items: center;
          //justify-content: center;
          overflow: hidden;

          &.current {
            width: 100% !important;

            .image img {
              transform: translateX(0%) !important;
            }
          }

          .image {
            position: relative;
            display: flex;
            flex-shrink: 0;
            //width: 1570px;
            //border: 1px solid red;
            margin: auto;

            img {
              width: 100%;
              transition: all 0.2s;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
