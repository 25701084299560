<template>
  <common-header></common-header>
  <router-view />
  <!--  <common-footer></common-footer>-->
</template>
<script>
// import CommonHeader from "@components/header/index.vue";
import CommonHeader from "@components/header/common-header.vue";

//import CommonFooter from "@components/footer.vue";
export default {
  components: {
    CommonHeader,
    //CommonFooter
  },
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
};
</script>
<style lang="scss"></style>
