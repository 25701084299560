<template>
  <div class="home">
    <top></top>
    <tags></tags>
    <program></program>
    <apply></apply>
    <about></about>
    <partner></partner>
    <attestation></attestation>
    <news></news>
    <CommonFooter></CommonFooter>
  </div>
</template>

<script>
import top from "./top";
import tags from "./tags";
import program from "./program";
import CommonFooter from "@components/footer.vue";
import apply from "./apply";
import news from "./news";
import about from "./about";
import partner from "./partner";
import attestation from "@/views/home/attestation.vue";
import "swiper/swiper-bundle.min.css";
export default {
  components: {
    top,
    tags,
    program,
    CommonFooter,
    apply,
    news,
    about,
    partner,
    attestation,
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .home {
    width: 100%;
  }
  .spaces {
    height: 1rem;
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
