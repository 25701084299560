<template>
  <div class="container">
    <div class="f1 title wow fadeInUp">产品中心</div>
    <div class="f2 wow fadeInUp">
      将射频识别（RFID）芯片与产品标签相结合，不仅使您的产品不可复制，更能有效进行产品追踪，从而极大提高仓储、物流、零售的效率，迈入智能制造与智能零售的新纪元。
    </div>
    <card
      :data="navs[current].children"
      :to="navs[current].to"
      :toName="navs[current].toName"
    ></card>
  </div>
</template>
<script>
import card from "./card";
export default {
  components: {
    card,
  },
  data() {
    return {
      current: 0,
    };
  },
  setup() {
    let navs = [
      {
        name: "RFID产品",
        children: [
          {
            navName: "RFID标签",
            mNavName: "RFID标签",
            name: "RFID标签",
            info: "RFID标签是一种无线电频率识别技术，由微处理器和天线组成的电子装置，用于在物品上识别和追踪信息。",
            example: [
              {
                name: "RFID/二维码标签",
                img: require("@/assets/home/tags/1-1.png"),
              },
              {
                name: "价格吊牌标签",
                img: require("@/assets/home/tags/1-2.png"),
              },
              {
                name: "织唛标签",
                img: require("@/assets/home/tags/1-3.png"),
              },
              {
                name: "标签贴纸",
                img: require("@/assets/home/tags/1-4.png"),
              },
            ],
            icon: require("@/assets/home/tags/icon1.png"),
          },
          {
            navName: "RFID读写器",
            mNavName: "RFID读写器",
            name: "RFID读写器",
            info: "RFID读写器是一种无线电频率识别设备，用于与RFID标签进行无线通信，读取和写入标签中存储的信息。",
            example: [
              {
                name: "一体式读写器",
                img: require("@/assets/home/tags/2-1.png"),
              },
              {
                name: "四通道",
                img: require("@/assets/home/tags/2-2.png"),
              },
              {
                name: "八通道",
                img: require("@/assets/home/tags/2-3.png"),
              },
              {
                name: "桌面式读写器",
                img: require("@/assets/home/tags/2-4.png"),
              },
            ],
            icon: require("@/assets/home/tags/icon2.png"),
          },
          {
            navName: "RFID手持机",
            mNavName: "RFID手持机",
            name: "RFID手持机",
            info: "RFID手持机通过RFID技术实现对标签的读取、写入、编程、扫描和数据处理等功能，适用于多种资产管理、库存管理和物流管理场景。",
            example: [
              {
                name: "超高频手持终端",
                img: require("@/assets/home/tags/3-1.png"),
              },
              {
                name: "智能手持终端",
                img: require("@/assets/home/tags/3-2.png"),
              },
              {
                name: "Zebra RFID读取器",
                img: require("@/assets/home/tags/3-3.png"),
              },
              {
                name: "工业手持终端",
                img: require("@/assets/home/tags/3-4.png"),
              },
            ],
            icon: require("@/assets/home/tags/icon3.png"),
          },
          {
            navName: "RFID打印机",
            mNavName: "RFID打印机",
            name: "RFID打印机",
            info: "RFID打印机是一种用于RFID标签制作的打印机，可以在标签上打印二维码、文字、编码等信息，适用于多种标签制作和资产管理场景。",
            example: [
              {
                name: "桌面打印机",
                img: require("@/assets/home/tags/4-1.png"),
              },
              {
                name: "条码打印机",
                img: require("@/assets/home/tags/4-2.png"),
              },
              {
                name: "Zebra 工业打印机",
                img: require("@/assets/home/tags/4-3.png"),
              },
              {
                name: "工业条码打印机",
                img: require("@/assets/home/tags/4-4.png"),
              },
            ],
            icon: require("@/assets/home/tags/icon4.png"),
          },
          {
            navName: "RFID智能柜",
            mNavName: "RFID智能柜",
            name: "RFID智能柜",
            info: "RFID智能柜是一种利用RFID技术实现对存放物品的自动识别、管理、保管和取用的柜子，适用于物品保管和取用自动化的场景。",
            example: [
              {
                name: "智能洗衣收发柜",
                img: require("@/assets/home/tags/5-1.png"),
              },
              {
                name: "工厂智能取衣柜",
                img: require("@/assets/home/tags/5-2.png"),
              },
              {
                name: "刷卡自助取衣柜",
                img: require("@/assets/home/tags/5-3.png"),
              },
              {
                name: "24小时洗衣柜",
                img: require("@/assets/home/tags/5-4.png"),
              },
            ],
            icon: require("@/assets/home/tags/icon5.png"),
          },
          {
            navName: "RFID硬件产品",
            mNavName: "RFID硬件产品",
            name: "RFID硬件产品",
            info: "RFID硬件产品是指配合RFID标签、RFID读写器、RFID天线等在内的用于实现RFID技术应用的各种物理设备和配件。",
            example: [
              {
                name: "智能带屏安全门禁",
                img: require("@/assets/home/tags/6-1.png"),
              },
              {
                name: "通道机",
                img: require("@/assets/home/tags/6-2.png"),
              },
              {
                name: "RFID自助收银机",
                img: require("@/assets/home/tags/6-3.png"),
              },
              {
                name: "超高频平板天线",
                img: require("@/assets/home/tags/6-4.png"),
              },
            ],
            icon: require("@/assets/home/tags/icon6.png"),
          },
        ],
        to: "/tags",
        toName: "产品详情",
      },
    ];
    return { navs };
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    padding-bottom: 0.8rem;
    background: #ffffff;

    @if ($searchWidth<1100) {
      padding-bottom: 2rem;
      .title{
        padding-top: 1.5rem;
      }
    } @else {
      .title {
        padding-top: 0.8rem;
      }
    }
  }

  @if ($searchWidth<1100) {
    .nav-box {
      padding: 0 1.6rem;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.2rem;
    background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    box-shadow: 0 0.02rem 0.11rem 0 rgba(83, 177, 165, 0.23);
    border: 0.02rem solid #ffffff;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;

    @if ($searchWidth<1100) {
      // width: 9.2rem;
      height: 2.4rem;
      border-radius: 1.2rem;
      width: 100%;
      padding: 0.4rem;
    } @else {
      padding: 0.06rem 0.1rem;
      width: 4.6rem;
      height: 0.62rem;
      border-radius: 0.31rem;
    }

    .nav-item {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.21rem;
      z-index: 10;
      transition: all 0.3s;
      text-align: center;
      cursor: pointer;

      @if ($searchWidth<1100) {
        font-size: 0.6rem;
      } @else {
        font-size: 0.24rem;
      }

      &.current {
        color: #fff;
      }
    }

    .nav-current {
      z-index: 9;
      width: 50%;
      border-radius: 0.21rem;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 0.3s;
      box-sizing: border-box;

      @if ($searchWidth<1100) {
        padding: 0.4rem;
      } @else {
        padding: 0.06rem 0.1rem;
      }

      > div {
        width: 100%;
        height: 100%;
        background: #5ec7b9;
        border-radius: 999999px;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
