<template>
  <div class="container">
    <div class="f1 title wow fadeInUp">新闻中心</div>
    <div class="navs">
      <div
        class="nav-item wow fadeInUp"
        :class="{ current: index === listCurrent }"
        v-for="(item, index) in list"
        :key="index"
        @click="navTab(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="main pc wow fadeInUp">
      <Swiper
        class="box"
        @Swiper="setSwiperInstance($event)"
        @slideChange="slideChange"
        :direction="'horizontal'"
        :slides-per-view="3"
        :slides-per-group="3"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :modules="modules"
      >
        <SwiperSlide
          v-for="(item, index) in sliceData(list[listCurrent].child)"
          :key="index"
        >
          <div class="item">
            <div class="image"><img :src="item.image" :alt="item.name" /></div>
            <!--            <div class="logo"><img :src="item.logo" :alt="item.name" /></div>-->
            <div class="date">{{ item.date }}</div>
            <div class="name">{{ item.title }}</div>
            <div class="detail" @click="goNews(item.href)">
              查看详情<img :src="require('@/assets/home/news/1.png')" />
            </div>
            <!--            <div class="info">{{ item.info }}</div>-->
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="bottom">
        <div
          class="bottom-btn"
          :class="{
            current: list[listCurrent].child.length > 3 && current === index,
          }"
          @click="bottomBtn(index)"
          v-for="(item, index) in bottomBtns"
          :key="index"
        />
      </div>
    </div>

    <div class="main moblie">
      <Swiper
        class="box"
        @Swiper="setSwiperInstanceMoblie($event)"
        @slideChange="slideChangeMoblie"
        :slides-per-view="1"
      >
        <SwiperSlide
          v-for="(item, index) in sliceData(list[listCurrent].child).slice(
            0,
            3
          )"
          :key="index"
        >
          <div class="item">
            <div class="image"><img :src="item.image" :alt="item.name" /></div>
            <!--            <div class="logo"><img :src="item.logo" :alt="item.name" /></div>-->
            <div class="date">{{ item.date }}</div>
            <div class="name">{{ item.title }}</div>
            <a class="detail" @click="goNews(item.href)" target="_blank">
              查看详情<img :src="require('@/assets/home/news/1.png')" />
            </a>
            <!--            <div class="info">{{ item.info }}</div>-->
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="bottom">
        <div
          class="bottom-btn"
          :class="{
            current:
              list[listCurrent].child.length > 3 && currentMoblie === index,
          }"
          @click="bottomBtnMoblie(index)"
          v-for="(item, index) in bottomBtnsMoblie"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Swiper as swiper, Mousewheel } from "swiper";
import { Autoplay } from "swiper";
import axios from "axios";
swiper.use(Mousewheel);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      modules: [Autoplay],
      listCurrent: 0,
      list: [
        {
          name: "公司新闻",
          child: [],
        },
        {
          name: "案例分享",
          child: [],
        },
        {
          name: "行业动态",
          child: [],
        },
      ],
      current: 0,
      currentMoblie: 0,
      mySwiper: null,
      mySwiperMoblie: null,
    };
  },

  mounted() {
    this.getData(`/news/6666-2/?timestamp=${new Date().getTime()}`, 0);
    this.getData(`/news/clothing-case/?timestamp=${new Date().getTime()}`, 1);
    this.getData(
      `/news/industry-dynamics/?timestamp=${new Date().getTime()}`,
      2
    );
  },
  computed: {
    bottomBtns() {
      return Math.ceil(
        this.sliceData(this.list[this.listCurrent].child).length / 3
      );
      // let len = this.sliceData(this.list[this.listCurrent].child).length;
      // return len - 2 >= 1 ? len - 2 : 1;
    },
    bottomBtnsMoblie() {
      let len = this.sliceData(this.list[this.listCurrent].child).length;
      return len > 3 ? 3 : len;
    },
  },
  methods: {
    sliceData(arr) {
      return arr.slice(0, 15);
    },
    navTab(index) {
      this.listCurrent = index;
      // this.current = 0;
      // this.currentMoblie = 0;
    },
    goNews(url) {
      this.$router.push({
        path: "/child",
        query: {
          url: url,
        },
      });
    },
    getData(url, index) {
      axios.get(url).then((res) => {
        let objE = document.createElement("div");
        objE.innerHTML = res.data;
        let doms = objE.querySelectorAll(".elementor-widget-container");
        // console.log(doms);
        let d = [];
        for (let i = 0, len = doms.length; i < len; i++) {
          let dom = doms[i];
          let imgSrc = dom.getElementsByTagName("img")[0]?.src;
          let title = dom.getElementsByTagName("h1")[0]?.innerText;
          let info = dom.getElementsByTagName("h2")[0]?.innerText;
          let href = dom.getElementsByTagName("h3")[0]?.innerText;
          let date = dom.getElementsByTagName("h4")[0]?.innerText;
          // console.log(imgSrc, title, info, href);
          d.push({
            title: title,
            info: info,
            date: date,
            image: imgSrc,
            href: href,
          });
        }
        this.list[index].child = d;
      });
    },
    setSwiperInstance($event) {
      // this.swiper = $event;
      this.mySwiper = $event;
    },
    setSwiperInstanceMoblie($event) {
      this.mySwiperMoblie = $event;
    },
    slideChange($event) {
      this.current = Math.ceil($event.activeIndex / 3);
      // this.current = Math.ceil($event.activeIndex);
    },
    slideChangeMoblie($event) {
      this.currentMoblie = Math.ceil($event.activeIndex);
    },
    bottomBtn(index) {
      this.current = index;
      this.mySwiper && this.mySwiper.slideTo(index * 3);
      // this.mySwiper && this.mySwiper.slideTo(index);
    },
    toLeftMoblie() {
      this.mySwiperMoblie.slidePrev();
    },
    toRightMoblie() {
      this.mySwiperMoblie.slideNext();
    },
    bottomBtnMoblie(index) {
      this.currentMoblie = index;
      this.mySwiperMoblie && this.mySwiperMoblie.slideTo(index);
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    background-image: url("~@/assets/home/apply/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      padding-top: 0.7rem;
      @if ($searchWidth<1100) {
        padding-top: 1.5rem;
      }
    }
  }

  .navs {
    display: flex;
    justify-content: center;
    margin-top: 0.36rem;
    @if ($searchWidth<1100) {
      margin-top: 1.8rem;
    }
    .nav-item {
      cursor: pointer;
      //font-size: 0.24rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: rgba(29, 64, 59, 0.45);
      letter-spacing: 0.01rem;
      //margin: 0 0.25rem;
      position: relative;
      padding-bottom: 0.14rem;
      @if ($searchWidth>1100) {
        font-size: 0.2rem;
        margin: 0 0.69rem;
      } @else {
        font-size: 0.8rem;
        margin: 0.5rem 0.25rem 0;
        min-width: 5rem;
        text-align: center;
      }
      &.current:after {
        content: "";
        position: absolute;
        background: #5ec7b9;
        left: 50%;
        transform: translateX(-50%);
        @if ($searchWidth>1100) {
          width: 0.9rem;
          height: 0.04rem;
          bottom: -0.04rem;
        } @else {
          width: 3.8rem;
          height: 0.15rem;
          bottom: -0.7rem;
        }
      }
    }
    .current {
      color: #333333;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    }
  }
  .main {
    width: 100%;
    position: relative;
    margin: 0 auto;
    @if ($searchWidth<1100) {
      padding: 1rem 2rem 2rem 1.25rem;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      &::v-deep {
        .swiper-initialized {
          overflow: initial !important;
        }
      }
    } @else {
      max-width: 13.2rem;
    }
    .l,
    .r {
      z-index: 11;
      position: absolute;
      top: 44%;
      background-size: 100%;
      @if ($searchWidth<1100) {
        width: 1.5rem;
        height: 1.5rem;
      } @else {
        width: 0.5rem;
        height: 0.5rem;
      }
      img {
        width: 100%;
        display: block;
      }
    }

    .l {
      @if ($searchWidth<1100) {
        left: 0.3rem;
      } @else {
        left: 0.1rem;
      }
      background-image: url("~@/assets/home/news/l.png");
      &:hover {
        background-image: url("~@/assets/home/news/l1.png");
      }
    }

    .r {
      @if ($searchWidth<1100) {
        right: 0.3rem;
      } @else {
        right: 0.1rem;
      }
      background-image: url("~@/assets/home/news/r.png");
      &:hover {
        background-image: url("~@/assets/home/news/r1.png");
      }
    }
  }

  .box {
    position: relative;
    @if ($searchWidth<1100) {
      margin: 0.5rem 0 0;
      width: 100%;
      display: flex;
      transition: all 0.2s;
    } @else {
      max-width: 13.2rem;
      margin: 0.23rem auto 0;
      //display: grid;
      //grid-template-columns: repeat(3, 1fr);
      //grid-column-gap: 0.4rem;
    }
    .item {
      transition: all 0.2s;
      background: #fcf9f9;
      box-shadow: 0px 2px 19px 0px rgba(83, 177, 165, 0.23);
      border-radius: 0.1rem;
      height: calc(100% - 0.54rem);
      box-sizing: border-box;
      margin: 0.2rem;
      @if ($searchWidth<1100) {
        height: calc(100% - 0.34rem);
        margin-right: 0.75rem;
        padding: 0.8rem;
        flex-shrink: 0;
      } @else {
        padding: 0.3rem 0.3rem 0.22rem 0.3rem;
        &:hover {
          box-shadow: 0px 2px 13px 0px rgba(83, 177, 165, 0.5);
          .image img {
            transform: scale(1.2);
          }
          .name {
            color: #5ec7b9;
          }
        }
      }
    }

    img {
      width: 100%;
      display: block;
      transition: all 0.2s;
    }

    .image {
      overflow: hidden;
      @if ($searchWidth<1100) {
        height: 10rem;
        width: 100%;
      } @else {
        height: 2.2rem;
        width: 3.5rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .date {
      font-family: 微软雅黑;
      font-weight: normal;
      color: #7a7a7a;
      @if ($searchWidth<1100) {
        font-size: 0.8rem;
        line-height: 1;
        margin-top: 0.5rem;
      } @else {
        font-size: 0.16rem;
        line-height: 37px;
      }
    }
    .name {
      font-family: 微软雅黑;
      font-weight: normal;
      color: #333333;
      transition: all 0.2s;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; // 作为弹性伸缩盒子模型显示。
      -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式--从上到下垂直排列
      -webkit-line-clamp: 2; // 显示的行
      @if ($searchWidth<1100) {
        font-size: 1rem;
        margin: 0.5rem 0;
        line-height: 1.1rem;
        height: 2.2rem;
      } @else {
        font-size: 0.2rem;
        line-height: 0.3rem;
        height: 0.6rem;
        margin: 0.1rem 0 0.43rem;
        //margin: 0.1rem 0;
      }
    }

    .detail {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #5ec7b9;
      border-top: 1px solid #c0c0c0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      @if ($searchWidth<1100) {
        font-size: 0.8rem;
        padding-top: 0.5rem;
        img {
          width: 0.8rem;
        }
      } @else {
        font-size: 0.16rem;
        padding-top: 0.15rem;
        img {
          width: 0.17rem;
        }
      }
      img {
        display: block;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    @if ($searchWidth<1100) {
      margin: 0.5rem 0 0 0;
    } @else {
      margin: 0.6rem 0;
    }
    .bottom-btn {
      cursor: pointer;
      border-radius: 50%;
      background: #d8d8d8;
      @if ($searchWidth<1100) {
        margin: 0.2rem;
        width: 0.4rem;
        height: 0.4rem;
      } @else {
        margin: 0.05rem;
        width: 0.12rem;
        height: 0.12rem;
      }
      &.current {
        background: #5ec7b9;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
