import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../lib/index.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "wow.js/css/libs/animate.css";
import WOW from "wow.js";
const wow = new WOW({
  boxClass: "wow",
  animateClass: "animated",
  offset: 0,
  mobile: true,
  live: true,
  scrollContainer: null,
  resetAnimation: true,
});
wow.init();

createApp(App).use(store).use(router).use(ElementPlus).mount("#app");
