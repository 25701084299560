<template>
  <div class="card wow fadeInUp">
    <div class="main">
      <div class="nav" v-if="isArray">
        <div
          class="nav-item"
          :class="{ current: current === index }"
          v-for="(item, index) in data"
          :key="index"
          @click="current = index"
        >
          {{ item.navName }}
        </div>
      </div>
      <div class="m-nav" v-if="isArray">
        <div
          class="nav-item"
          :class="{ current: current === index }"
          v-for="(item, index) in data"
          :key="index"
          @click="current = index"
        >
          {{ item.mNavName }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <img :src="currentData.icon" />{{ currentData.name }}
        </div>
        <div class="content-info">{{ currentData.info }}</div>
        <div class="equipment-warp">
          <div
            class="equipment_item"
            v-for="(item, index) in currentData.example"
            :key="index"
          >
            <div class="equipment_img">
              <img :src="item.img" :alt="item.name"/>
            </div>
            <div class="equipment_name" v-if="item.name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="more" @click="goDetial(current)">{{ toName }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: [Array, Object],
      default: () => {},
    },
    to: String,
    toName: String,
  },
  data() {
    return {
      current: 0,
    };
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.current = 0;
      },
    },
  },
  computed: {
    currentData() {
      return this.isArray ? this.data[this.current] : this.data;
    },
    isArray() {
      return Array.isArray(this.data);
    },
  },
  methods: {
    goDetial(index) {
      this.$router.push({
        name: "Tags",
        query: {
          current: index,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@mixin searchWidth($searchWidth) {
  .card {
    box-shadow: 0 0.04rem 0.11rem 0.03rem rgba(83, 177, 165, 0.23);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @if ($searchWidth<1100) {
      min-height: 4.5rem;
      margin: 0 0.8rem;
      padding: 0 0 0.8rem 0;
      border-radius: 0.5rem;
      overflow: hidden;
      background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
      box-shadow: 0px 4px 11px 3px rgba(83,177,165,0.22);
    } @else {
      height: 4.8rem;
      //min-height: 4.5rem;
      width: 13.2rem;
      margin: 0 auto;
      padding: 0.4rem 0.4rem 0.3rem 0;
      background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);
    }
  }

  .main {
    display: flex;
    align-items: flex-start;

    @if ($searchWidth<1100) {
      flex-direction: column;

      .nav {
        display: none;
      }
    } @else {
      .m-nav {
        display: none;
      }
    }
  }

  .m-nav {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .nav-item {
      width: 25%;
      font-size: 0.7rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      text-align: center;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.current {
        background: #5ec7b9;
        color: #fff;
      }
    }
  }

  .nav {
    width: 2.38rem;
    height: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
    border-right: 0.03rem solid #e5e6eb;
    cursor: pointer;

    .nav-item {
      font-size: 0.24rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      padding: 0.08rem 0 0.08rem 0.27rem;
      box-sizing: border-box;
      line-height: 0.34rem;
      border-left: 0.04rem solid transparent;
      &.current {
        border-left: 0.04rem solid #5ec7b9;
        padding: 0.08rem 0 0.08rem 0.27rem;
        color: #5ec7b9;
      }

      &:not(:first-child) {
        margin-top: 0.15rem;
      }
    }
  }

  .content {
    margin: 0 0.4rem;
    .content-title {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      @if ($searchWidth<1100) {
        font-size: 1rem;
        margin-top: 1.1rem;

        img {
          width: 1rem;
          margin-right: 0.5rem;
        }
      } @else {
        font-size: 0.3rem;

        img {
          width: 0.4rem;
          margin-right: 0.2rem;
        }
      }
    }

    .content-info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        line-height: 0.9rem;
        margin: 0.35rem 0 0.5rem;
      } @else {
        font-size: 0.16rem;
        line-height: 0.25rem;
        margin: 0.22rem 0 0.3rem;
      }
    }
  }

  .equipment-warp {
    display: flex;
    @if ($searchWidth<1100) {
      flex-wrap: wrap;
    }
    .equipment_item {
      margin-right: 0.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      @if ($searchWidth<1100) {
        margin: 6px 6px 20px;
        width: calc(50% - 12px);
      }
      .equipment_img {
        width: 2.2rem;
        overflow: hidden;
        @if ($searchWidth<1100) {
          width: 100%;
        }
        img {
          display: block;
          width: 100%;
          &:hover {
            transform: scale(1.2);
            transition: all 0.2s;
          }
        }
      }
      .equipment_name {
        font-size: 0.16rem;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #9da8a8;
        padding: 3px 14px;
        border: 2px solid #b0b7b7;
        margin-top: 0.15rem;
        @if ($searchWidth<1100) {
          border: 1px solid #b0b7b7;
          margin-top: 0.5rem;
          font-size: 0.6rem;
        }
      }
    }
  }

  .image {
    flex-shrink: 0;

    @if ($searchWidth<1100) {
      // width: 100%;
      margin: 0.5rem 0.8rem 0;

      .image1 {
        display: none;
      }
    } @else {
      width: 5.6rem;

      .image2 {
        display: none;
      }
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .more {
    background: #5ec7b9;
    text-align: center;
    //display: inline-block;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #77e0d2;
    }
    @if ($searchWidth<1100) {
      height: 40px;
      width: 122px;
      line-height: 40px;
      border-radius: 8px;
      font-size: 0.7rem;
      margin: 15px 0 20px 6px;
    } @else {
      height: 0.46rem;
      width: 1.4rem;
      line-height: 0.34rem;
      border-radius: 0.08rem;
      font-size: 0.18rem;
      padding: 0.06rem 0.32rem;
      box-sizing: border-box;
      margin-top: 0.35rem;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
