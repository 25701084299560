<!--关于我们中使用的小组件-->
<template>
  <div class="num-warp">
    <div class="top-warp" ref="target">
      <span class="numms" v-if="isShow">
        <CountTo :startVal="0" :endVal="nums" :duration="3000"></CountTo
      ></span>
      <span class="unit">{{ unit }}</span>
    </div>
    <div class="mes">
      {{ mes }}
    </div>
  </div>
</template>
<script>
import { CountTo } from "vue3-count-to";
import { useIntersectionObserver } from "@vueuse/core";
export default {
  props: ["nums", "unit", "mes"],
  components: {
    CountTo,
  },
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    const target = this.$refs.target;
    useIntersectionObserver(target, ([{ isIntersecting }]) => {
      if (isIntersecting) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .num-warp {
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    color: #5ec7b9;
    margin-bottom: 0.4rem;
    display: flex;
    flex-direction: column;
    @if ($searchWidth<1100) {
      margin-bottom: 0;
      min-width: 86px;
      margin-right: 32px;
    }
    .top-warp {
      display: flex;
      align-items: baseline;
    }
    .numms {
      font-size: 0.4rem;
      font-weight: normal;
      letter-spacing: 1px;
      @if ($searchWidth<1100) {
        margin-bottom: 0;
        font-size: 30px;
      }
    }
    .unit {
      font-size: 0.24rem;
      @if ($searchWidth<1100) {
        margin-bottom: 0;
        font-size: 16px;
      }
    }
    .mes {
      color: #7a7a7a;
      font-size: 0.16rem;
      @if ($searchWidth<1100) {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
