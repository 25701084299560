<template>
  <div class="dropDown">
    <div class="main">
      <div>
        <div class="title" :style="`background-image: url(${dropData.bgImg})`" @click="goHomePage(dropData.to)">
          {{ dropData.name }}
        </div>
        <div class="br"></div>
        <div class="child" :class="{ planChild: dropData.child.length > 6 }">
          <div
            v-for="(childItem, childIndex) in dropData.child"
            :key="childIndex"
            :class="{
              planChildWarp: dropData.child.length > 6 && childIndex % 2 != 0,
            }"
            @click="goDetials(childIndex, childItem.name)"
          >
            <div class="name">
              <img :src="require('@/assets/dropDeader/' + childItem.icon)" />{{
                childItem.name
              }}
            </div>
            <div class="info">{{ childItem.mes }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const dropDownData = [
  {
    name: "RFID产品描述",
    to: "/tags?current=0",
    bgImg: require("@/assets/dropDeader/productBg.png"),
    child: [
      {
        name: "RFID标签",
        icon: "1-1.png",
        mes: "用于识别跟踪和管理资产或个体",
      },
      {
        name: "RFID读写器",
        icon: "1-2.png",
        mes: "读取、写入和处理RFID标签的信息",
      },
      {
        name: "RFID手持机",
        icon: "1-3.png",
        mes: "通过RFID技术实现对标签的读取",
      },
      {
        name: "RFID打印机",
        icon: "1-4.png",
        mes: "用于多种标签制作和资产管理场景",
      },
      {
        name: "RFID智能柜",
        icon: "1-5.png",
        mes: "适用于物品保管和取用自动化的场景",
      },
      {
        name: "RFID硬件产品",
        icon: "1-6.png",
        mes: "实现RFID技术应用的物理设备和配件",
      },
    ],
  },
  {
    name: "解决方案",
    to: "/plans?name=衣服盘点",
    bgImg: require("@/assets/dropDeader/planBg.png"),
    child: [
      {
        name: "贴标管理",
        icon: "2-1.png",
        mes: "电子标签进行编码并与服装进行绑定",
      },
      {
        name: "快速查找",
        icon: "2-5.png",
        mes: "用RFID技术方便快捷的找到指定服装",
      },
      {
        name: "衣服盘点",
        icon: "2-2.png",
        mes: "RFID技术实现库存货品的快速盘点",
      },
      {
        name: "快速收银",
        icon: "2-6.png",
        mes: "RFID自助收银，大大节省结账时间",
      },
      {
        name: "智能货架",
        icon: "3-3.png",
        mes: "自动扫描识别货物信息,跟踪货物的位置",
      },
      {
        name: "防盗防损",
        icon: "2-7.png",
        mes: "检测未付款商品离开店面发现警报",
      },
      {
        name: "智能试衣间",
        icon: "2-4.png",
        mes: "统计衣物的试用次数以及购买率",
      },
    ],
  },
  {
    name: "关于常达",
    to: "/about#全球业务",
    bgImg: require("@/assets/dropDeader/aboutBg.png"),
    child: [
      {
        name: "全球业务",
        icon: "3-1.png",
        mes: "全球40+国家办事处，8国生产线",
      },
      {
        name: "公司简介",
        icon: "3-2.png",
        mes: "自主研发团队开发RFID系统平台",
      },
      {
        name: "生产实力",
        icon: "3-3.png",
        mes: "常达控股在8个国家设有工厂",
      },
      {
        name: "联系我们",
        icon: "3-4.png",
        mes: "专业售前服务、实施服务、售后服务",
      },
    ],
  },
];
export default {
  data() {
    return {};
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.dropData = dropDownData[this.index];
  },
  methods: {
    goHomePage(url){
      this.$router.push(url);
    },
    goDetials(number, name) {
      let urlList = ["/tags?current=", "/plans?name=", "/about#"];
      let url = urlList[this.index] + (this.index == 0 ? number : name);
      window.location = url;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  font-size: 16px;
  padding-bottom: 0.2rem;
  padding-top: 0.27rem;
  .title {
    font-size: 0.2rem;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0.16rem 0 0.16rem 0.3rem;
    position: relative;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      left: 0.2rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.03rem;
      height: 0.18rem;
      background: linear-gradient(31deg, #97e5dd 0%, #5ec7b9 100%);
    }
  }
  .br {
    width: 100%;
    height: 0.02rem;
    opacity: 0.45;
    background: #c0c0c0;
    margin-top: 0.23rem;
  }
  .child {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.22rem;
    > div {
      max-width: 2.4rem;
    }
    .name {
      font-size: 0.18rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
    }
    .name:hover {
      color: #5ec7b9;
      cursor: pointer;
    }
    img {
      width: 0.25rem;
      display: block;
      margin-right: 0.15rem;
    }
    .info {
      font-size: 0.14rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;
      line-height: 0.25rem;
      margin-top: 0.09rem;
    }
  }
  .planChild {
    padding: 0 0.24rem;
    > div {
      width: calc(50% - 0.2rem);
    }
  }
  .planChildWarp {
    margin-left: 0.4rem;
  }
}
</style>
